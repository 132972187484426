import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';

import 'scss/styles.scss';

import Intro from '../components/Intro/Intro';
import LiveStream from '../components/LiveStream/LiveStream';

function LiveStreamPage() {
  const mainRef = useRef<HTMLDivElement>(null);

  return (
    <div id="main" ref={mainRef}>
      <Helmet title="HSE" />
      <Intro />
      <LiveStream />
    </div>
  );
}

export default LiveStreamPage;
