import React, { useEffect, useRef, useState } from 'react';

import VideoSection from '../VideoSection/VideoSection';
import Icon from 'assets/icons/arrow-down.inline.svg';
import useScroll from 'hooks/useScroll';

import styles from './Intro.module.scss';

function Intro() {
  const isBrowser = typeof window !== 'undefined';
  const videoSectionRef = useRef<HTMLDivElement>(null);
  const [videoFinishedPlaying, setVideoFinishedPlaying] = useState(false);
  const isVideoPlaying = useRef(false);
  const hasScrolledDown = useRef(false);
  const { y } = useScroll();

  const scrollToNextSection = () => {
    window.scrollTo({ behavior: 'smooth', top: videoSectionRef.current?.clientHeight, left: 0 });
    hasScrolledDown.current = true;
  };

  const onSkipToNextSectionClick = () => {
    scrollToNextSection();

    // if video is playing, pause it
    if (isVideoPlaying.current) {
      const video = videoSectionRef.current?.querySelector('video');
      if (video) {
        video.pause();
      }
    }
  };

  useEffect(() => {
    const videoSection = videoSectionRef.current;
    if (videoSection && videoSection.clientHeight > y) {
      // autoplay video if it is in viewport on first render
      if (!videoFinishedPlaying) {
        const video = videoSection.querySelector('video');
        if (video) {
          isVideoPlaying.current = true;
          video.play();
        }
      } else if (!hasScrolledDown.current) {
        // Automatically scroll to next section if video finished playing
        // while the video is still in the viewport

        scrollToNextSection();
      }
    }
  }, [videoFinishedPlaying, y]);

  return (
    <div className={styles.video_intro}>
      <VideoSection
        type="inline"
        ref={videoSectionRef}
        sources={
          isBrowser && window.innerWidth <= 768
            ? [{ src: require('assets/videos/intro_4x5.mp4'), type: 'video/mp4' }]
            : [{ src: require('assets/videos/intro.mp4'), type: 'video/mp4' }]
        }
        onEnded={() => setVideoFinishedPlaying(true)}
        autoPlay={false}
      >
        <button type="button" className={styles.scroll_button} onClick={onSkipToNextSectionClick}>
          <span>skip intro</span>
          <Icon />
        </button>
      </VideoSection>
    </div>
  );
}

export default Intro;
