import React from 'react';
import classes from './LiveStream.module.scss';

const LiveStream = () => {
  const streamUrl = 'https://player.gl-systemhaus.de/hse24-event/index.html';

  return (
    <div className={classes.liveStreamContainer}>
      <header className={classes.header}>
        <h1 className={classes.headline}>
          Herzlich willkommen zu unseren virtuellen HSE Veranstaltungen
        </h1>
      </header>
      <div className={classes.videoContainer}>
        <iframe className={classes.video} src={streamUrl} frameBorder="0" allowFullScreen></iframe>
      </div>
    </div>
  );
};

export default LiveStream;
