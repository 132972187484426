import React, {
  ForwardedRef,
  forwardRef,
  ReactNode,
  SyntheticEvent,
  useRef,
  useState,
  VideoHTMLAttributes,
} from 'react';
import classNames from 'classnames';

import styles from './VideoSection.module.scss';

type VideoSource = {
  src: string;
  type: string;
};

type VideoSources = VideoSource[];

interface Props extends Omit<VideoHTMLAttributes<HTMLVideoElement>, 'src'> {
  sources: VideoSources;
  children?: ReactNode;
  type?: 'background' | 'inline';
  withCustomControls?: boolean;
}

function VideoSection(props: Props, ref: ForwardedRef<HTMLDivElement>) {
  const {
    autoPlay = true,
    muted = true,
    playsInline = true,
    loop = false,
    preload = 'metadata',
    controls = false,
    disablePictureInPicture = true,
    type = 'background',
    withCustomControls = false,
    ...rest
  } = props;
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const onPlayToggleClick = () => {
    const video = videoRef.current;
    if (video) {
      const action = video.paused ? 'play' : 'pause';
      video[action]();
      setIsPlaying(action === 'play');
    }
  };

  const handleOnEnded = (event: SyntheticEvent<HTMLVideoElement, Event>) => {
    setIsPlaying(false);
    props.onEnded?.(event);
  };

  return (
    <div
      ref={ref}
      className={classNames(styles.video_section, {
        [styles.video_section_background]: type === 'background',
      })}
    >
      {withCustomControls && (
        <button
          type="button"
          className={classNames(styles.video_section_button, {
            [styles.video_section_button_playing]: isPlaying,
            [styles.video_section_button_paused]: !isPlaying,
          })}
          onClick={onPlayToggleClick}
        />
      )}
      <video
        ref={videoRef}
        autoPlay={autoPlay}
        muted={muted}
        loop={loop}
        preload={preload}
        controls={controls}
        disablePictureInPicture={disablePictureInPicture}
        playsInline={playsInline}
        onEnded={handleOnEnded}
        {...rest}
      >
        {props.sources.map((src, i) => (
          <source key={i} {...src} />
        ))}
        Sorry, your browser doesn't support embedded videos.
      </video>
      {props.children}
    </div>
  );
}

export default forwardRef(VideoSection);
